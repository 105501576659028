.home-container {
  font-family: 'Roboto', sans-serif;
  padding: 20px;
}

.hero-section {
  text-align: center;
  padding: 0px 20px;
  opacity: 0;
  animation: fadeIn 2s forwards;
  animation-delay: 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.hero-section h1 {
  font-size: 3em;
  margin-bottom: 20px;
  font-weight: 800;
  color: var(--color-heading);
}

.hero-section p {
  font-size: 1.5em;
  margin-bottom: 30px;
  font-weight: 300;
  color: var(--color-text);
}

.cta-button {
  background: var(--color-primary);
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1em;
  cursor: pointer;
  opacity: 0;
  animation: fadeIn 2s 1s forwards; /* Add delay */
}

.demo-cta {
  text-decoration: unset;
  color: var(--color-text);
  display: block;
  font-size: 0.8em;
  margin-top: 10px;
  opacity: 0;
  animation: fadeIn 2s 1.5s forwards; /* Add delay */
}

.home-container h2 {
  font-size: 2em;
  font-weight: 800;
  color: var(--color-heading);
}

.story-features-container {
  background-color: var(--color-gradient-start);
  background-image: linear-gradient(147deg, var(--color-gradient-start) 0%, var(--color-gradient-end) 74%);
  width: 100vw;
  padding: 50px 20px;
  text-align: center;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 4s ease-in-out, transform 5s ease-in-out;
}

.story-section,
.features-section,
.bottom-section {
  padding: 50px 0;
}

.story-section p {
  font-size: 1.5em;
  color: var(--color-text);
}

.story-section p,
.features-section p,
.testimonial p {
  line-height: 1.5;
}

.story-section p,
.testimonial p {
  padding: 2em;
}

.features-section{
  background-image: radial-gradient(#444df74e 1.1px, #ffffff00 1.1px);
  background-size: 40px 40px;
}

.features-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.feature-item{
  background: var(--color-feature-bg);
  color: white;
  padding: 20px;
  margin: 10px;
  width: 200px;
  border-radius: 10px;
  box-shadow: 0 4px 10px var(--color-feature-shadow);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  transition: transform 0.2s ease-in-out, background 0.2s ease-in-out;
  opacity: 0;
  transform: translateY(20px);
}

.feature-item:hover {
  background: var(--color-feature-hover);
  transform: scale(1.1);
}

.feature-item .feature-icon{
height: 4em;
border-radius: 15px;
    padding: 10px;
    background: white;
    box-shadow: 0 4px 10px var(--color-feature-shadow);
}

.feature-item h3 {
  color: whitesmoke;
}

.testimonial-section {
  padding: 50px 20px;
  text-align: center;
}

.testimonial-section .testimonial {
  background: #f5f5f5;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  max-width: 600px;
  margin: 20px auto;
}

.bottom-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  text-align: center;
  opacity: 0;
  transition: opacity 5s ease-in-out, transform 1s ease-in-out;
}

.bottom-section h1 {
  padding: 2em;
  width: 60%;
  color: var(--color-heading);
}

.screens-gif {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 2em;
  align-items: center;
  text-align: center;
  opacity: 0;
  animation: fadeIn 2s 1s forwards; /* Add delay */
}

.screens {
  width: 60%;
  border-radius: 8px;
  box-shadow: 0 4px 10px #d8d8d8;
  max-width: 800px;
}

.screens-gif h1 {
  padding: 1em;
  width: 60%;
  color: var(--color-heading);
}

@media (max-width: 767px) {
  .screens {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .feature-item p {
    padding: 0.5em;
  }
}
