/* src/css/WorkspaceSettings.css */
.settings {
    display: flex;
    flex-direction: column;
    gap: 1em;
    font-size: 1em;
    color: #333;
    transition: width 0.3s, height 0.3s;
    background-color: var(--color-background);
    padding: 8px 8px 8px 20px;
    border-radius: 10px;
  }
  
  .setting-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .toggle-switch {
    position: relative;
    width: 60px;
    height: 34px; /* Added height to match the slider height */
    display: inline-block;
  }
  
  .toggle-switch input {
    display: none;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  input:not(:checked) + .slider {
    background-color: #ccc;
  }
  
  input:not(:checked) + .slider:before {
    background-color: white;
  }
  
  .side-menu .settings-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .side-menu button.reset-button, .side-menu button.save-button {
    color: var(--color-primary);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: fit-content;
    align-self: end;
    font-size: 0.9em;
  }
  
  .side-menu button.reset-button:hover, .side-menu button.save-button:hover {
    color: lightgray;
  }
  