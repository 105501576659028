.workspace-container {
  text-align: left; 
  margin: 20px;
  margin-bottom: 0px;
  max-width: 800px;
  min-width: 800px;
  width: 100%;
}

.workspace-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; 
}

.workspace-title {
  font-size: 1.5em;
  margin: 0; 
  color: var(--color-text);
}

.workspace-title-input {
  font-size: 1.5em;
  border: none;
  border-bottom: 1px solid var(--color-primary);
}

.workspace-action-btns {
  display: flex;
  gap: 1em;
}

.open-all-btn, .edit-btn, .delete-workspace-btn {
  padding: 10px 10px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--color-primary);
}

.edit-btn.pressed {
  background-color: var(--color-primary-light); 
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.5);
}

.delete-workspace-btn {
  background-color: rgba(220, 20, 60, 0.857);
}

.collapse-btn {
  background-color: unset;
}

.collapse-icon {
  height: 2em;
  width: 2em;
  color: var(--color-text);
  transition: transform 0.3s;
}

.collapse-icon.rotated {
  transform: rotate(180deg);
}

.workspace-list {
  display: flex;
  align-items: center;
  gap: 20px;
  overflow: hidden;
  display: inline-flex;
}

.workspace-list.has-many-items .swiper:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 20px; 
  background: linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  pointer-events: none;
  z-index: 2;
  border-radius: 10px;
}

.mySwiper {
  max-width: 800px;
  min-width: 800px;
  width: 100%;
}

.mySwiper .swiper-slide {
  width: auto;
}

.workspace-list .swiper .swiper-scrollbar {
  position: relative;
  margin-top: 0.5em;
  overflow: hidden;
}

.workspace-list .swiper .swiper-scrollbar::-webkit-scrollbar-thumb {
  color: aqua;
}

.workspace-screenshot {
  background-color: #ddd;
  height: 100px;
}

.popup-blocked-message {
  text-align: center;
  font-weight: bold;
}

.workspace-separator {
  border: none;
  border-top: 0.5px solid var(--color-gradient-end);
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Modal Styles */
/* .modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 400px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.confirm-btn, .cancel-btn {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  color: white;
}

.modal button.confirm-btn {
  background-color: #d9534f;
  color: white;
}

.modal button.cancel-btn {
  background-color: #5bc0de;
  color: white;
} */

@media (max-width: 767px) {
  .workspace-container {
    width: 100%;
    display: inline-grid;
    min-width: unset;
    margin: 0;
  }
  .mySwiper {
    min-width: unset;
  }
  .workspace-header {
    justify-content: space-between;
    gap: 0.5em;
    padding: 0.5em;
  }
  .workspace-list p {
    padding: 0 20px; 
  }
  .workspace-title {
    word-break: break-word;
  }
  .workspace-title-input {
    width: 40%;
  }
  .delete-workspace-btn {
    width: min-content;
  }
}
