/* src/css/AddProjectModal.css */
@import './Modal.css';

.modal-content input,
.modal-content select {
  line-height: 1.5;
  font-size: 1em;
  margin-top: 10px;
}

.error {
  color: red;
  margin-top: 10px;
}
