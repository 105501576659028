.full-width {
  width: 100%;
}

.sticky-nav {
  position: sticky;
  top: 0;
  background-color: unset;
  padding: 10px 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
}

.sticky-nav h2 {
  margin: 0 20px;
}

.sticky-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.sticky-nav li {
  margin: 0 5px;
}

/* .sticky-nav a {
  color: white;
  text-decoration: none;
} */

.menu-button {
  background: none;
  border: none;
  color: var(--color-heading);
  font-size: 1em;
  cursor: pointer;
  border: 1px solid var(--color-heading);
  font-weight: 300;
  border-radius: 20px;
  padding: 0.1em 1em 0.1em 1em;
  transition: width 0.3s, height 0.3s;
}

.menu-button:hover {
  transform: scale(1.1);
  background-color: var(--color-gradient-end);
}

nav li img.user-avatar {
  cursor: pointer;
}

.full-width .bottom-line {
  border-bottom: 1px solid var(--color-gradient-end);
  width: 75%;
  margin: 0 auto;
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.logo-img {
  height: 40px; /* Adjust size as needed */
  margin-right: 10px; /* Adjust spacing as needed */
}

.sticky-nav a.logo-link {
  color: var(--color-primary);
  font-weight: 800;
}

.sticky-nav .pricing-btn a {
  font-weight: 300;
  color: var(--color-heading);
  font-size: 1em;
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: none;
}

.sticky-nav .pricing-btn a:hover {
  text-decoration: none;
  background-color: var(--color-gradient-end);
}

@media (max-width: 767px) {
  .logo-text {
    display: none;
  }
  .sticky-nav {
    justify-content: space-between;
    margin: 0 auto;
    width: 90%;
  }
}
