.zentimer-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.zentimer-button {
    border: none;
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    color: #ccc;
    cursor: pointer;
    transition: background 1s linear, color 1s linear;
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    width: 200px;
}

.zentimer-wrapper .directions{
width: 80%;
}

.timer-settings {
    margin-top: 10px;
    position: absolute;
    top: 100%;
    background: white;
    padding: 20px;
    padding-top: 40px;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
}

.toggle-buttons .close-settings {
    color: #aaa;
    font-size: 1em;
    font-weight: bold;
    background: none;
    border: none;
    top: 0;
    right: 0;
    position: absolute;
}

.timer-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.timer-input div {
    display: flex;
    align-items: center;
}

.timer-input input {
    padding: 10px;
    font-size: 16px;
    margin-right: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 30px;
    text-align: center;

}

.timer-input input[type="number"]::-webkit-outer-spin-button,
.timer-input input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.timer-input span {
    font-size: 1em;
    color: #333;
    margin-left: 10px;
    margin-right: 10px;
}

.timer-input button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    background-color: var(--color-primary);
    color: white;
    cursor: pointer;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.overlay-content {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    text-align: center;
}

.overlay-content h2 {
    margin-bottom: 20px;
}

.overlay-content button {
    padding: 10px 20px;
    font-size: 16px;
    margin: 0 10px;
    border: none;
    border-radius: 4px;
    background-color: var(--color-primary);
    color: white;
    cursor: pointer;
}

.timer-type-toggle{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 1em;
    margin-top: 1em;
}

.overlay-actions{
display: flex;
flex-direction: column;
gap: 1em;
color: #aaa;
}
@media screen and (max-width: 768px) {
    .zentimer-wrapper{
        display: none;
    } 
}