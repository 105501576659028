/* src/comps/Footer.css */

.footer {
  color: var(--color-heading);
  padding: 20px 0;
  position: static;
  width: 100%;
  bottom: 0;
  text-align: center;
  z-index: 500;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer ul {
  list-style: none;
  margin: 10px 0 0;
  padding: 0;
  display: flex;
  font-size: 1em;
}

.footer li {
  margin: 0;margin-left: 10px;
}

.footer a {
  color: var(--color-heading);
  text-decoration: none;
  font-size: 1em;
}

.footer a:hover {
  text-decoration: underline;
}

.footer .bottom-line {
  border-bottom: 1px solid var(--color-gradient-end);
  width: 60%;
  margin: 0 auto;
}
