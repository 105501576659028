/* src/css/DeleteProjectModal.css */
@import './Modal.css';

.modal-content {
  text-align: center;
}

.modal-actions {
  margin-top: 20px;
}

.confirm-btn {
  background-color: red;
}

.confirm-btn:hover {
  background-color: darkred;
}

.cancel-btn {
  background-color: gray;
}

.cancel-btn:hover {
  background-color: darkgray;
}
