@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Color Variables */
:root {
  --color-background: #ffffff;
  --color-text: #282C35;
  --color-primary: #368ce7;
  --color-primary-light: #bedaf7;
  --color-heading: #1666ba;
  --color-feature-bg: #079CFF;
  --color-feature-hover: #368ce7;
  --color-feature-shadow: #007bff;
  --color-gradient-start: #c3cbdc;
  --color-gradient-end: #deecfb;
  --color-workspcae-info:#f9f9f9;
  --color-workspace-border:none;
  --color-sidemenu-bg:#f5f5f5;
  --color-sidemenu-btn-background: #ffffff;
  --color-sidemenu-btn--text: black;
}

body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Poppins', 'Roboto', Arial, sans-serif;
  background-color: var(--color-background);
  overflow-x: hidden;
  color: var(--color-text);
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
}

* {
  font-family: 'Poppins', 'Roboto', sans-serif;
}

button {
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 400;
}

button:hover {
  background-color: var(--color-primary-light);
}

h1, h2 {
  color: var(--color-heading);
}

/* Dark Mode */
.dark-mode {
  --color-background: #121212;
  --color-text: #ffffff;
  --color-primary: #aaa;
  --color-primary-light: #3700b3;
  --color-heading: #bb86fc;
  --color-feature-bg: #03dac6;
  --color-feature-hover: #018786;
  --color-feature-shadow: #03dac6;
  --color-gradient-start: #333;
  --color-gradient-end: #444;
  --color-workspcae-info:#555;
  --color-workspace-border:#555;
  --color-sidemenu-bg:#444;
  --color-sidemenu-btn-background: #555;
  --color-sidemenu-btn--text: #aaa;
}
