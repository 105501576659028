/* src/css/DismissibleNotification.css */

.notification {
  background-color: #f0f8ff;
  border: 1px solid #b0c4de;
  padding: 10px 20px;
  border-radius: 4px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-text);
}

.close-button {
  background: none;
  border: none;
  color: #888;
  font-size: 1.2em;
  cursor: pointer;
}

.close-button:hover {
  color: #555;
}
