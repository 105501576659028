/* src/css/BlogPost.css */
.blogpost-container {
  font-family: 'Roboto', sans-serif;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.blogpost-container h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
  font-weight: 800;
  color: var(--color-text);
}

.blogpost-date {
  font-size: 1em;
  color: #d0d0d0;
  margin-bottom: 20px;
}

.blogpost-content {
  font-size: 1.2em;
  line-height: 1.6;
  color: var(--color-text);
}

.blogpost-content h2 {
  margin-top: 20px;
  font-size: 1.5em;
  font-weight: 700;
  color: var(--color-text);
}

.blogpost-content ul {
  padding-left: 20px;
  list-style-type: disc;
}
