/* src/css/Search.css */

.search-form {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 25px;
  overflow: hidden;
  width: 350px;
  margin: 1em auto;
  margin-top: 3em;
  background-color: var(--color-background);
}

.search-form input {
  border: none;
  padding: 1em;
  outline: none;
  flex-grow: 1;
  border-radius: 25px 0 0 25px;
  background-color: unset;
}

.search-form:hover {
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.search-form button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 1em;
  padding-left: 2em;
  padding-right: 2em;
  height: 100%;
}

.search-form button:hover, .search-form input:hover {
  background-color: #f0f0f0;
}

.search-form button svg {
  transition: width 0.3s, height 0.3s;
}

.search-form button:hover svg {
  height: 2em;
  width: 2em;
}
