/* src/css/SideMenu.css */

.side-menu {
  position: absolute;
  top: 60px; /* Adjust this value based on your nav height */
  right: 20px; /* Align it to the right end of the nav */
  width: 300px;
  padding: 25px;
  background-color: var(--color-sidemenu-bg);
  border-radius: 1em;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 1000;
}

.side-menu button.close-button {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 10px;
  right: 10px;
  cursor: pointer;
  border-radius: 50%;
  padding: 0.5em;
  height: 2em;
  width: 2em
}

.side-menu button.close-button:hover {
  background-color: lightgray;
}

.side-menu button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: var(--color-sidemenu-btn-text);
  cursor: pointer;
  outline: inherit;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

.settings-btn {
  margin-right: 10px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  top: 10px;
  right: 10px;
  cursor: pointer;
  border-radius: 50%;
  padding: 0.5em;
  height: 1.4em;
  width: 1.4em
}

.side-menu button .settings-btn:hover {
  background-color: lightgray;
}
.user-info {
  text-align: center;
  margin-bottom: 20px;
}

.user-info p {
  font-weight: 500;
  color: var(--color-heading);
}

.user-avatar {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 0.5px solid var(--color-gradient-end);
}

.menu-items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-items li {
  margin: 15px 0;
  font-size: 1em;
  color: #333;
  transition: width 0.3s, height 0.3s;
  background-color: var(--color-sidemenu-btn-background);
  padding: 8px;
  border-radius: 10px;
}

.menu-items a {
  color: var(--color-sidemenu-btn-text);
  text-decoration: none;
  font-size: 1em;
  display: flex;
  align-items: center;
}

.menu-items li:hover {
  transform: scale(1.1);
  margin-left: 15px;
  background-color: var(--color-gradient-end);
}

.side-menu .user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.side-menu .user-info .user-avatar {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.side-menu .user-info p {
  margin: 0;
  font-size: 1.2em;
}

.side-menu .user-info .user-plan {
  margin-top: 5px;
  font-size: 0.8em;
  color: var(--color-primary);
  cursor: pointer;
  background-color: var(--color-gradient-end);
  padding: 2px 15px;
  border: 1px solid var(--color-primary);
  border-radius: 15px;
}

.side-menu .user-info .user-plan:hover {
  color: var( --color-feature-hover);
  background-color: var(--color-background);
}

.side-menu-mode-btn{
  position: absolute;
  display: flex;
  justify-content: center;
  top: 10px;
  left: 10px;
  cursor: pointer;
  border-radius: 50%;
  padding: 0.5em;
  height: 2em;
  width: 2em;
}