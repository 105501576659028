/* src/css/Modal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: var(--color-background);
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    max-width: 80%;
    display: flex;
    flex-direction: column;
  }
  
  .modal-content h2 {
    margin-top: 0;
    color: var(--color-heading);
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .modal-actions button {
    padding: 10px 20px;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .modal-content button {
    background-color: var(--color-primary);
  }
  
  .modal-content button:hover {
    background-color: #0056b3;
  }
  
  .close {
    position: absolute;
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  