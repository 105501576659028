.todo-list {
    margin-top: 20px;
  }
  
  .todo-list .top-todo {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .todo-list .top-todo .todo-buttons{
    display: flex;
    gap: 1em;
  }

  .todo-list .add-todo-form {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-bottom: 20px;
  }
  
  .todo-list .ql-container {
    min-height: 100px; /* Adjust based on line height */
  }
  
  .todo-list .ql-editor {
    min-height: 5em; /* Adjust based on font size and line height */
  }
  
  .todo-list ul {
    list-style-type: none;
    padding: 0;
  }
  
  .todo-list li {
    background: #f9f9f9;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    position: relative;
  }
  
  .todo-list li .delete-todo-btn {
    position: absolute;
    top: 4px;
    right: 4px;
    background: none;
    border: none;
    color: #d9534f;
    font-size: 16px;
    cursor: pointer;
  }
  
  .todo-list button {
    margin-top: 10px;
    padding: 5px 10px;
    cursor: pointer
  }
  
  .todo-list button.edit-mode-btn {
    display: flex;
    background: unset;
    border: none;
    cursor: pointer;
    margin-bottom: 0px;
    color: var(--color-gradient-start);
    font-size: 1.5em;
  }
  
  .todo-list button.edit-mode-btn:hover {
    background-color: unset;
    color: var(--color-primary);
  }

    @media (max-width: 767px) {
    .todo-list{
      padding-left: 10px;
      padding-right: 10px;
    }
  }