.apphome {
  display: flex;
  flex-direction: column;
}

.no-workspaces {
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  margin-top: 20px;
}

.no-workspaces h2 {
  font-size: 2em;
  margin-bottom: 10px;
  color: var(--color-text);
}

.no-workspaces p {
  font-size: 1.2em;
  color: var(--color-text);
}

.no-workspaces ol {
  text-align: left;
  margin: 0 auto;
  display: inline-block;
  font-size: 1em;
}

.no-workspaces ol li {
  margin-bottom: 1em;
}

@media (max-width: 767px) {
  .no-workspaces {
    width: min-content;
  }
}

.toggle-buttons {
  display: flex;
  justify-content: left;
  margin-bottom: 1em;
  margin-top: 1em;
}

.toggle-buttons button {
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  color: #ccc;
  padding: 10px 20px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s;
}

.toggle-buttons button.active {
  background-color: var(--color-primary);
  color: white;
  border: 1px solid var(--color-primary);
}
