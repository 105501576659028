.kanban-board {
    display: flex;
    justify-content: space-between;
  }
  
  .kanban-column {
    background-color: #f4f5f7;
    border-radius: 5px;
    padding: 10px;
    width: 30%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
  }
  
  .top-kanban{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .kanban-buttons{
    display: flex;
    gap: 1em;
  }

  .kanban-task {
    position: relative;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .delete-task-btn {
    position: absolute;
    top: 4px;
    right: 4px;
    background: none;
    border: none;
    color: #d9534f;
    font-size: 16px;
    cursor: pointer;
  }
  
  .add-task-form {
    display: flex;
    margin-bottom: 20px;
    gap: 1em;
    justify-content: center;
    align-items: center;
  }
  
  .add-task-form input {
    flex: 1;
    padding: 10px;
    font-size: 16px;
  }
  
  .add-task-form button {
    margin-top: 10px;
    padding: 5px 10px;
    cursor: pointer
  }
  
  .kanban-buttons .edit-mode-btn {
    display: flex;
    background: unset;
    border: none;
    cursor: pointer;
    margin-bottom: 0px;
    color: var(--color-gradient-start);
    font-size: 1.5em;
  }
  
  .kanban-buttons .edit-mode-btn:hover {
    background-color:unset;
    color: var(--color-primary);
  }

  .kanban-buttons button{
    padding: 5px 10px;
    cursor: pointer;
  }

  @media (max-width: 767px) {
    .top-kanban, .add-task-form, .kanban-board{
      padding-left: 10px;
      padding-right: 10px;
      gap: 1em;
    }
  }