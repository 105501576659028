.UpgradePlan-modal-content {
  background-color: var(--color-background);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: center;
}

.UpgradePlan-modal-content button {
  background-color: var(--color-primary);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.UpgradePlan-modal-content button:hover {
  background-color: #0056b3;
}

.UpgradePlan-modal-content .close {
position: relative;
}