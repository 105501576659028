.screens-gif {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 2em;
  align-items: center;
  text-align: center;
}

.screens {
  width: 60%;
  border-radius: 8px;
  box-shadow: 0 4px 10px #d8d8d8;
  max-width: 800px;
}

.screens-gif h1 {
  padding: 1em;
  width: 60%;
  color: var(--color-heading);
}

@media (max-width: 767px) {
  .screens {
    width: 80%;
  }
}
