.privacy-policy-container {
  padding: 20px;
  font-family: 'Roboto', sans-serif;
}

.privacy-policy-container h1, .privacy-policy-container h2 {
  color: var(--color-heading);
}

.privacy-policy-container p, .privacy-policy-container ul {
  line-height: 1.6;
  color: var(--color-text);
}

.privacy-policy-container ul {
  list-style-type: disc;
  padding-left: 20px;
}
