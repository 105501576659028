/* src/css/AddComponentModal.css */
@import './Modal.css';

.modal-content {
  position: relative;
}

.modal-actions {
  margin-top: 10px;
}

.toggle-group {
  display: flex;
  flex-direction: column;
}

.toggle-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.toggle-item span {
  margin-left: 5px;
}

.info-icon {
  margin-right: 10px;
  cursor: pointer;
}

.info-icon:hover {
  color: var(--color-primary-dark);
}

.info-image-container {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
  z-index: 1001;
  text-align: center;
}

.info-image-container img {
  width: 50vw;
  max-height: 500px;
  border: 1px solid #ccc;
  border-radius: 15px;
}

.close-info {
  margin-top: 10px;
  padding: 5px 10px;
  border: none;
  background-color: var(--color-primary);
  color: white;
  cursor: pointer;
}

.close-info:hover {
  background-color: #0056b3;
}

.modal-content .slider:before {
  left: 1px;
} 

