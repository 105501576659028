/* Web view (unchanged) */
.pricing {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.action-area {
  margin-top: 20px;
  text-align: center;
}

.get-started-btn, .submit-btn {
  background-color: var(--color-primary);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.get-started-btn:hover, .submit-btn:hover {
  background-color: #0056b3;
}

.waitlist-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 250px;
  margin-right: 10px;
}

.logged-in-message {
  font-size: 1.2em;
  color: green;
}

.waitlist-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 10px;
}

.success-message {
  color: green;
  font-size: 0.9em;
  margin-top: 10px;
}

/* Mobile view */
@media (max-width: 768px) {
  .pricing {
    flex-direction: column;
    align-items: center;
  }

  .pricing-option {
    width: 90%;
    max-width: 300px;
  }

  .waitlist-input {
    width: 90%;
    max-width: 300px;
    margin-bottom: 10px;
  }

  .get-started-btn, .submit-btn {
    width: 90%;
    max-width: 300px;
  }
}
