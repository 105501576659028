/* src/css/WorkspaceItem.css */

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Space between items */
}

.workspace-item {
  height: 200px; /* Increased height for more content space */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add box shadow */
  display: flex;
  flex-direction: column;
  justify-content: end; /* Distribute space between elements */
  border-radius: 8px;
  margin: 10px;
  width: 200px;
  overflow: hidden;
  position: relative;
  background: var(--color-background);
  transition: transform 0.3s ease;
  border: 1px var( --color-workspace-border) solid
}

.workspace-item:hover {
  transform: scale(1.05);
}

.workspace-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link-preview-image {
  width: 60px;
  height: 60px;
  object-fit: scale-down;
  margin: 10px; /* Add padding to the icons */
  border-radius: 15px;
}

.workspace-info {
  /* background-color: var(--color-workspcae-info); */
  padding: 10px;
  text-align: center;
  width: 100%;
  max-height: 120px;
  min-height: 80px;
  box-sizing: border-box; /* Ensure padding is included in the height */
  flex-grow: 1;
  border-top-right-radius: 30px;
}

.workspace-info:hover {
  /* background-color: var(--color-gradient-end); */
}

.workspace-info h3 {
  color: #aaa; /* Light grey for the title */
  font-size: 0.9em; /* Smaller font size */
  margin: 5px 0; /* Adjust margin */
}

.workspace-info p {
  color: #bbb; /* Lighter grey for the description */
  font-size: 0.8em; /* Smaller font size */
  margin: 5px 0; /* Adjust margin */
}

.workspace-info a {
  color: #bbb; /* Lighter grey for the link */
  font-weight: bold; /* Bold for the link */
  font-size: 0.8em; /* Smaller font size */
  text-decoration: none; /* No underline */
  margin: 5px 0; /* Adjust margin */
  word-wrap: break-word; /* Ensure long URLs break to the next line */
}

.workspace-info a:hover {
  color: #888; /* Slightly darker grey on hover */
}

.workspace-item-actions .delete-btn svg, .workspace-item-actions .edit-btn svg {
  width: 1.5em;
  height: 1.5em;
}

.workspace-item-actions {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  position: absolute;
  top: 0.5em;
  right: 0em;
  margin-right: 1em;
}

.workspace-item-actions .edit-btn,
.workspace-item-actions .delete-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: transform 0.3s, color 0.3s;
}

.workspace-item-actions .edit-btn:hover,
.workspace-item-actions .delete-btn:hover {
  transform: scale(1.1);
}

.workspace-item-edit {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 1em;
}

.workspace-item-edit input {
  line-height: 1.5;
}

.type-icon {
  margin-right: 10px;
  color: var(--color-primary);
  position: absolute;
  left: 5%;
  top: 5%;
}
