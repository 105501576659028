/* src/css/Projects.css */

.projects-container {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 0px;
  max-width: 800px;
  min-width: 800px;
  width: 100%;
}

.project-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-action-btns {
  display: flex;
  gap: 1em;
  flex: none;
}

.add-project-btn {
  padding: 10px 10px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.edit-project-btn {
  padding: 10px 10px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-component-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  color: var(--color-primary);
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 10px;
}

.add-component-btn:hover {
  color: var(--color-primary-dark);
}

.projects-tabs {
  display: flex;
  overflow-x: auto;
  margin-bottom: 20px;
  align-items: center;
  overflow: hidden;
}

.project-name {
  display: flex;
  border-radius: 0;
  padding: 10px 20px;
  margin-right: 10px;
  color: #ccc;
  background-color: unset;
  cursor: pointer;
  font-weight: normal;
  font-size: 1em;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.project-name.active {
  font-weight: bold;
  color: var(--color-primary);
  background-color: var(--color-primary-light);
  border-color: var(--color-primary);
}

.project-name:hover {
  color: var(--color-primary);
}

.delete-project-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  color: crimson;
  margin-left: 10px;
  height: 1.2em;
  width: 1.2em;
}

.delete-project-btn:hover {
  color: black;
}

.project-tabs {
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
}

.project-tab {
  border-radius: 0;
  padding: 10px 20px;
  margin-right: 10px;
  color: #ccc;
  background-color: unset;
  cursor: pointer;
}

.project-tab.active {
  color: var(--color-primary);
  border-bottom: 3px solid var(--color-primary);
  font-weight: bold;
}

.selected-project {
  margin-top: 20px;
}

.no-components {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.no-components p {
  margin: 0;
  color: #888;
}

@media (max-width: 767px) {
  .projects-container {
    min-width: unset;
    width: 100%;
  }
  .project-header {
    flex-direction: column;
    gap: 0.5em;
  }
  .projects-tabs {
    width: -webkit-fill-available;
    padding-left: 10px;
    padding-right: 10px;
  }
  .project-action-btns {
    justify-content: center;
    width: -webkit-fill-available;
    display: flex;
    justify-content: start;
    padding-left: 10px;
    padding-right: 10px;
  }
  .project-name {
    padding: 10px;
    margin-right: 5px;
  }
  .project-tab {
    padding: 10px;
    margin-right: 5px;
  }
  .project-tabs{
  padding-left: 10px;
  padding-right: 10px;
  }
  .add-project-btn, .edit-project-btn {
    padding: 8px;
    font-size: 0.9em;
  }
}
