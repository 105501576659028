/* src/css/NotFound.css */
.not-found-container {
    text-align: center;
    padding: 50px;
  }
  
  .not-found-container h1 {
    font-size: 3em;
    margin-bottom: 20px;
  }
  
  .not-found-container p {
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  
  .back-home-link {
    font-size: 1.2em;
    color: var(--color-primary);
    text-decoration: none;
    border: 2px solid var(--color-primary);
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .back-home-link:hover {
    background-color: var(--color-primary);
    color: #fff;
  }
  