.notes {
    margin-top: 20px;
  }
  
  .top-notes {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .ql-container {
    min-height: 200px; /* Adjust this value based on the line height */
  }
  
  .ql-editor {
    min-height: 5em; /* Adjust this value based on the font size and line height */
  }
  
  .notes button {
    margin-top: 10px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  @media (max-width: 767px) {
    .notes{
      padding-left: 10px;
      padding-right: 10px;
    }
  }