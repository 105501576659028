.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background-color: var(--color-background);
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 2em;
}

.login-container h2 {
  margin-bottom: 20px;
  color: var(--color-heading);
}

.login-container span {
  margin-top: 10px;
  font-weight: 300;
  font-size: small;
  color: var(--color-text);
}

.side-menu button.google-login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  color: #333;
  background-color: white;
  border: 1px solid #ccc;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 15px;
  transition: transform 0.3s, color 0.3s;
}

.google-login-btn:hover {
  transform: scale(1.05);
  color: var(--color-primary);
}

.google-login-btn:focus {
  outline: none;
}
