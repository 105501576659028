.all-apps {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-apps {
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  margin-top: 20px;
}

.no-apps h2 {
  font-size: 2em;
  margin-bottom: 10px;
  color: var(--color-text);
}

.no-apps p {
  font-size: 1.2em;
  color: var(--color-text);
}

.app-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.app-list .workspace-item {
  margin: 10px;
}
