.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: var(--color-background);
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 400px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: var(--color-text);
  text-decoration: none;
  cursor: pointer;
}

label {
  display: block;
  margin-bottom: 10px;
}

.modal input {
  width: -webkit-fill-available;
  padding: 8px;
  margin-bottom: 20px;
}

.modal button {
  padding: 10px 20px;
  color: var(--color-primary-light);
  border: none;
  cursor: pointer;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.button-group button {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  color: #aaa;
  background-color: #f1f1f1;
}

.button-group button.active {
  background-color: var(--color-primary);
  color: white;
  border-color: var(--color-primary);
}

.button-group button:not(.active):hover {
  background-color: #f1f1f1;
}

.styled-select {
  width: 100%;
}

.app-option {
  display: flex;
  align-items: center;
}

.app-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.selected-app-icon img {
  width: 100px;
  height: 100px;
}

.modal-content button.submit {
  color: white;
}

.modal-content button.submit.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.modal-content .radio-btns {
  margin-bottom: 20px;
}

.modal-content .radio-btns input {
  width: auto;
}

.modal-content .radio-btns .radio-text {
  font-size: small;
  font-style: italic;
}

.modal-content.new-item h2 {
  margin-bottom: 0;
}

.modal-content.new-item span {
  margin-bottom: 1em;
}
