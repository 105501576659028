.pricing {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.pricing-option {
  position: relative;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  width: 210px;
  text-align: center;
  margin: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.pricing-option h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: var(--color-heading);
}

.pricing-option h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: var(--color-heading);
}

.pricing-option ul {
  list-style: none;
  padding: 0;
  text-align: left;
}

.pricing-option li {
  margin: 5px 0;
  display: flex;
  align-items: flex-start; /* Align items to the top */
}

.feature-icon {
  margin-right: 8px;
  color: var(--color-primary);
  flex-shrink: 0; /* Prevent the icon from shrinking */
  font-size: 1.2em; /* Adjust icon size if necessary */
}

.pricing-option:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.recommended .ribbon {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #ff0;
  color: #000;
  padding: 5px 10px;
  transform: rotate(15deg);
  font-weight: bold;
}

.selected {
  border-color: var(--color-primary);
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}
