.add-workspace-button {
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 20px auto;
  cursor: pointer;
  border-radius: 8px;
  background-color: var(--color-primary);
}
