.login-page {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
  }
  
  .login-page .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-background);
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 30%;
    width: 30%;
  }
  
  .login-page .login-container .google-login-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1em;
    color: #333;
    background-color: white;
    border: 1px solid #ccc;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 15px;
    transition: transform 0.3s, color 0.3s;
  }
  
  @media screen and (max-width: 767px) {
    .login-page .login-container {
      width: 80%;
      height: 20%;
    }
  }
  