/* src/css/Blog.css */
.blog-container {
  font-family: 'Roboto', sans-serif;
  padding: 20px;
}

.blog-hero-section {
  text-align: center;
  padding: 30px 20px;
  background-color: #f0f4f8;
  border-radius: 30px;
  margin-bottom: 2em;
}

.blog-hero-section h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
  font-weight: 800;
  color: #003366;
}

.blog-hero-section p {
  font-size: 1.5em;
  margin-bottom: 30px;
  font-weight: 300;
  color: #666666;
}

.blog-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog-item-link {
  text-decoration: none;
  width: 100%;
  display: flex;
  justify-content: center;
}

.blog-item {
  background: var(--color-feature-hover);
  color: white;
  padding: 20px;
  margin: 10px;
  width: 80%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  text-align: left;
}

.blog-item:hover {
  background: var(--color-primary);
  transform: scale(1.05);
}

.blog-item h2 {
  color: whitesmoke;
  margin-bottom: 10px;
}

.blog-date {
  font-size: 0.9em;
  color: #d0d0d0;
  margin-bottom: 10px;
}
