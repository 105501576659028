.add-btn {
  font-size: 3em;
  color: var(--color-text);
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  transition: transform 0.3s, color 0.3s;
}

.add-btn:hover {
  transform: scale(1.1);
  background-color: unset;
  color: var(--color-primary);
}

.add-btn:focus {
  outline: none;
}
